import React, { useState } from 'react'
import { Link } from 'gatsby'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { FormContact } from '../components/contact-form';
import { MenuOutlined, CaretRightFilled } from '@ant-design/icons'
import { Theme } from '../styles/theme';
import { Button, Row, Col } from 'antd';
import ModalVideo from 'react-modal-video'

const HomePage = () => {
	const menuOpenStyle = {
		visibility: 'visible',
		display: 'block',
		opacity: 1,
		transform: 'scale(1)',
		top: 135,
		zIndex: 10000,
		pointerEvents: 'all'
	}

	const [menuOpen, setMenuOpen] = useState(false)

	const toggleMenu = () => {
		setMenuOpen(!menuOpen)
	}

	const backgrounds = [
		{
			image: '/imgs/sliders/slider1.jpg',
			subtext: 'Skills | Equity | Brokering',
			title: 'Early Investment into Property Development'
		},
		{
			image: '/imgs/sliders/slider2.jpg',
			title: 'Early investment into student accomodation development',
			subtext: 'Three Investment opportunities'
		},
		{
			image: '/imgs/sliders/slider3.jpg',
			title: 'Three Investment opportunities',
			subtext: 'S12J, Private equity, crowd funding'
		},
		{
			image: '/imgs/sliders/slider4.jpg',
			subtext: 'We see equity being created for everyone, by connecting those with capital to those with great property development projects shared wealth is created for everyone involved.',
			title: 'About Us'
		}
	]
	const j12 = 'Section 12J of the Income Tax Act No. 58 of 1962 was legislated by the South African Government to encourage South African taxpayers to invest in local companies and receive a 100% tax deduction of the value of their investment. The investor in return receives a Section 12J tax certificate and Venture Capital shares. The invested amount can be deducted from the investor’s taxable income, in the year the investment is made.'
	const mosesProfile = 'Moses was trained in Innovation, where he developed the skills for system analysis. He uses that skill to maintain control and win in the property development sector'

	const [showVideo, setShowVideo] = useState(false)

	const openVideo = () => {
		setShowVideo(true)
	}

	const closeVideo = () => {
		setShowVideo(false)
	}

    return (
        <div id="page" className="site">
			<ModalVideo isOpen={showVideo} channel='youtube' videoId='P50Dp1BMIxY' onClose={closeVideo} />
	<a className="skip-link screen-reader-text" href="#content">Skip to content</a>

		
	<header id="masthead" className="site-header">
	
	<div className="container">
		<div className="top-section d-none d-md-block">
			<div className="row">
				<div className="left-content col-md-6 col-12 text-md-left text-center">
				<span className="location"><i className="fa fa-map-marker" aria-hidden="true"></i>Plot 293, Protea Road Cnr Methley Road, Pomona AH, 1619</span>				</div>
				<div className="botton-section col-md-6 col-12 text-md-right text-center">
										<ul>
						<li><i className="fa fa-phone" aria-hidden="true"></i>(+27) 012 546 0966</li>
						<li><i className="fa fa-clock-o" aria-hidden="true"></i>08AM - 5PM</li>
					</ul>				</div>
			</div>
		</div>
		<div className="row bottom-section align-items-center">
			<div className="site-branding col-md-4 col-sm-6 col-lg-3 col-9">
				<Link to='/' className="custom-logo-link" rel="home"><img width='280px' src="imgs/logo.png" className="custom-logo" alt="Airi" /></Link>			</div>

			<div className="header-mobile-menu col-lg-9 col-md-8 col-sm-6 col-3">
				<button className="mobile-menu-toggle" aria-controls="primary-menu" onClick={toggleMenu}>
					<MenuOutlined style={{color: Theme.secondaryColor}} />
					{/* <span className="mobile-menu-toggle_lines"></span>
					<span className="sr-only" style={{background: 'green'}}>Toggle mobile menu</span> */}
				</button>
			</div>			
			<div className="right-content col-xl-9 col-md-12">
				<div className="d-flex align-items-center justify-content-end">
					<nav id="site-navigation" className="main-navigation" style={menuOpen ? menuOpenStyle : {}} onClick={toggleMenu}>
						<div className="menu-menu-container"><ul id="primary-menu" className="menu"><li id="menu-item-141" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-4 current_page_item menu-item-141"><Link to='/' aria-current="page">Home</Link></li>
<li id="menu-item-143" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-143"><a href='#about'>About</a></li>
<li id="menu-item-143" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-143"><a href='#investment'>Investment</a></li>
<li id="menu-item-143" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-143"><Link to="/projects">Projects</Link></li>
<li id="menu-item-144" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-144"><Link to='/contact'>Contact</Link></li>
{/* <li id="menu-item-147" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-147"><Link to='/team'>Team</Link></li> */}
</ul></div>					</nav>
					<div className="group-actions d-none d-xl-flex align-items-center">

						<div className="search-form">
									
									{/* <div className="header-search-form">					</div> */}
						</div>
													<Link to='/contact' className="btn">
								Invest							</Link>
											</div>
				</div>
			</div>
		</div>
	</div>


</header>
	<div id="content" className="site-content">

	
	<div id="primary" className="content-area">
		<main id="main" className="site-main" role="main">

									<div data-elementor-type="wp-post" data-elementor-id="4" className="elementor elementor-4" data-elementor-settings="[]">
			<div className="elementor-inner">
				<div className="elementor-section-wrap">
							
						<Carousel autoPlay interval={5000} showStatus={false} infiniteLoop transitionTime={500}>
							<div style={{height: '100%', backgroundImage: `url(/imgs/sliders/slider5.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
								<section style={{backgroundImage: 'none', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} className="elementor-element elementor-element-3a72b6ee elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="3a72b6ee" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classNameic&quot;}">
										<div onClick={openVideo} style={{cursor: 'pointer', width: 150, height: 150, borderRadius: '50%', background: 'rgba(0,0,0,0.75)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
										 <CaretRightFilled style={{fontSize: 70, color: 'white'}} />
										</div>
								</section>
							</div>
							{backgrounds.map((background, index) => {
								return (
									
										<div key={index} style={{height: '100%', backgroundImage: `url(${background.image})`, backgroundSize: 'cover'}}>
										<section style={{backgroundImage: 'none'}} className="elementor-element elementor-element-3a72b6ee elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="3a72b6ee" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classNameic&quot;}">
														<div className="elementor-container elementor-column-gap-no">
												<div className="elementor-row">
												<div className="elementor-element elementor-element-7f4eab65 elementor-column elementor-col-100 elementor-top-column" data-id="7f4eab65" data-element_type="column">
											<div className="elementor-column-wrap  elementor-element-populated">
													<div className="elementor-widget-wrap">
												<div className="elementor-element elementor-element-355f8151  elementor-widget elementor-widget-heading" data-id="355f8151" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="heading.default">
												<div className="elementor-widget-container">
											<h3 className="elementor-heading-title elementor-size-default">{background.title}</h3>		</div>
												</div>
												<div className="elementor-element elementor-element-6920f3b2  elementor-widget elementor-widget-heading" data-id="6920f3b2" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="heading.default">
												<div className="elementor-widget-container">
								<p className="elementor-heading-title elementor-size-default" style={{color: 'white', fontSize: '1.8em'}}>{background.subtext}</p>		</div>
												</div>
												<div className="elementor-element elementor-element-34b42f40 elementor-align-left  elementor-widget elementor-widget-button" data-id="34b42f40" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}" data-widget_type="button.default">
												<div className="elementor-widget-container">
													<div className="elementor-button-wrapper">
											<Link to='/contact' className="elementor-button-link elementor-button elementor-size-sm" style={{background: Theme.secondaryColor, color: 'white'}} role="button">
														<span className="elementor-button-content-wrapper">
														<span className="elementor-button-text" >Invest</span>
										</span>
													</Link>
										</div>
												</div>
												</div>
														</div>
											</div>
										</div>
														</div>
											</div>
										</section>
										</div>
									
								)
							})} 
							
							</Carousel>

							
				<section id='sliderbg' className="elementor-element elementor-element-7567c609 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="7567c609" data-element_type="section">
						<div className="elementor-container elementor-column-gap-no">
				<div id="about" className="elementor-row">
				<div className="elementor-element elementor-element-470bfcbf elementor-column elementor-col-50 elementor-top-column" data-id="470bfcbf" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-7f8baf9 elementor-widget elementor-widget-heading" data-id="7f8baf9" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h3 className="elementor-heading-title elementor-size-default">Introducing The Joseph Company</h3>		</div>
				</div>
				<div className="elementor-element elementor-element-11df3c40 elementor-widget elementor-widget-heading" data-id="11df3c40" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h4 className="elementor-heading-title elementor-size-default">Exclusive investment opportunities for investors</h4>		</div>
				</div>
				<div className="elementor-element elementor-element-4deb347e elementor-widget elementor-widget-heading" data-id="4deb347e" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<p className="elementor-heading-title elementor-size-default">The Joseph Company (TJC) connects equity investors with early-stage property developments, using the expertise, experience and network that its founders built up over the last decade in their own successful developments.
</p>		
	<Link to='/about'><Button type='primary' style={{background: Theme.secondaryColor, border: 'none', height: 50, width: 160, marginTop: 20}}>Read More</Button></Link>
</div>
				</div>
				<div className="elementor-element elementor-element-7bb42af6 elementor-widget elementor-widget-heading" data-id="7bb42af6" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			{/* <p className="elementor-heading-title elementor-size-default">TJC has established key partners to offer multiple attractive investment mechanisms. We offer opportunities to become equity partners, investments enabled by tax refunds thanks to Section 12J of the Income tax act, and more.</p>		 */}
			</div>
				</div>
				<div className="elementor-element elementor-element-15cb140 elementor-widget elementor-widget-image" data-id="15cb140" data-element_type="widget" data-widget_type="image.default">
				<div className="elementor-widget-container">
					<div className="elementor-image">
										{/* <img width="160" height="52" src="uploads/sites/96/2019/05/singunar.png" className="attachment-full size-full" alt="" />											 */}
                                        </div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-768575cb elementor-column elementor-col-50 elementor-top-column" data-id="768575cb" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-276ab2d5 elementor-widget elementor-widget-image" data-id="276ab2d5" data-element_type="widget" data-widget_type="image.default">
				<div className="elementor-widget-container">
					<div className="elementor-image">
										<img src="imgs/building.jpg" title="about-hp.jpg" alt="about-hp.jpg" />											</div>
				</div>
				</div>
				<section className="elementor-element elementor-element-7b2072dc elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="7b2072dc" data-element_type="section">
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-5722b19b elementor-column elementor-col-50 elementor-inner-column" data-id="5722b19b" data-element_type="column">
			<div className="elementor-column-wrap">
					<div className="elementor-widget-wrap">
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-126ad5bb elementor-column elementor-col-50 elementor-inner-column" data-id="126ad5bb" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-13126918 elementor-view-default elementor-widget elementor-widget-icon" data-id="13126918" data-element_type="widget" data-widget_type="icon.default">
				<div className="elementor-widget-container">
					<div className="elementor-icon-wrapper">
			<div className="elementor-icon">
							<i className="fa fa-gavel" aria-hidden="true"></i>
						</div>
		</div>
				</div>
				</div>
				<div className="elementor-element elementor-element-1f17a18 elementor-widget__width-inherit elementor-absolute elementor-widget elementor-widget-counter" data-id="1f17a18" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="counter.default">
				<div className="elementor-widget-container">
					<div className="elementor-counter">
			<div className="elementor-counter-number-wrapper">
				<span className="elementor-counter-number-prefix"></span>
				<span className="elementor-counter-number" data-duration="2000" data-to-value="98" data-from-value="0" data-delimiter=",">100</span>
				<span className="elementor-counter-number-suffix">%</span>
			</div>
							<div className="elementor-counter-title">Success Rate</div>
					</div>
				</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
				<section id="investment" className="elementor-element elementor-element-30594932 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="30594932" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classNameic&quot;}">
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-23b79d66 elementor-column elementor-col-100 elementor-top-column" data-id="23b79d66" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-4ebaa8fd elementor-widget elementor-widget-heading" data-id="4ebaa8fd" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h3 className="elementor-heading-title elementor-size-default">Multiple Investment Mechanisms</h3>		</div>
				</div>
				<div className="elementor-element elementor-element-13e639d5 elementor-widget elementor-widget-heading" data-id="13e639d5" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			{/* <p className="elementor-heading-title elementor-size-default">A full-service digital agency approach to grow your brand online</p>		 */}
            </div>
				</div>
				<section className="elementor-element elementor-element-508d812d elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="508d812d" data-element_type="section">
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-5e16e967 elementor-column elementor-col-33 elementor-inner-column" data-id="5e16e967" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-64621f49 elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="64621f49" data-element_type="widget" data-widget_type="icon-box.default">
				<div className="elementor-widget-container">
					<div className="elementor-icon-box-wrapper">
						<div className="elementor-icon-box-icon">
				<span className="elementor-icon elementor-animation-" >
				<i className="fa fa-balance-scale" aria-hidden="true" style={{color: 'white'}}></i>				</span>
			</div>
						<div className="elementor-icon-box-content">
				<h3 className="elementor-icon-box-title">
					<span ></span>
				</h3>
								<p className="elementor-icon-box-description">12J</p>
							</div>
		</div>
				</div>
				</div>
				<div className="elementor-element elementor-element-18b94528 elementor-widget elementor-widget-heading" data-id="18b94528" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<p className="elementor-heading-title elementor-size-default"><span  style={{fontWeight: 'bold', textDecorationLine: 'visible'}}>Get a tax refund to invest</span><br />{j12}
</p>
<Link to="/invest-12j" className="elementor-button-link elementor-button elementor-size-sm" style={{background: Theme.secondaryColor, color: 'white', marginTop: 10}} role="button">
						<span className="elementor-button-content-wrapper">
						<span className="elementor-button-text" >Invest your taxes</span>
		</span></Link>
		</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-5d22b615 elementor-column elementor-col-33 elementor-inner-column" data-id="5d22b615" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-a85d7be elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="a85d7be" data-element_type="widget" data-widget_type="icon-box.default">
				<div className="elementor-widget-container">
					<div className="elementor-icon-box-wrapper">
						<div className="elementor-icon-box-icon">
				<span className="elementor-icon elementor-animation-" >
				<i className="fa fa-heart" aria-hidden="true" style={{color: 'white'}}></i>				</span>
			</div>
						<div className="elementor-icon-box-content">
				<h3 className="elementor-icon-box-title">
					<span ></span>
				</h3>
								<p className="elementor-icon-box-description">Private Equity</p>
							</div>
		</div>
				</div>
				</div>
				<div className="elementor-element elementor-element-7914b5ed elementor-widget elementor-widget-heading" data-id="7914b5ed" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<p className="elementor-heading-title elementor-size-default"><span  style={{fontWeight: 'bold'}}>Become an equity partner</span><br />a minimum investment of R500k in which you/company will own shares/equity of any of the property developments that we have. we believe that private equity has a significant role to play in driving economic growth by generating returns for investors and building better businesses through real value that goes beyond equity.
</p>		
<Link to="/equity-partner" className="elementor-button-link elementor-button elementor-size-sm" style={{background: Theme.secondaryColor, color: 'white', marginTop: 10}} role="button">
						<span className="elementor-button-content-wrapper">
						<span className="elementor-button-text" >Become an equity partner</span>
		</span></Link>
        </div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-6b2cc187 elementor-column elementor-col-33 elementor-inner-column" data-id="6b2cc187" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-15995908 elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="15995908" data-element_type="widget" data-widget_type="icon-box.default">
				<div className="elementor-widget-container">
					<div className="elementor-icon-box-wrapper">
						<div className="elementor-icon-box-icon">
				<span className="elementor-icon elementor-animation-" >
				<i className="fa fa-bank" aria-hidden="true" style={{color: 'white'}}></i>				</span>
			</div>
						<div className="elementor-icon-box-content">
				<h3 className="elementor-icon-box-title">
					<span ></span>
				</h3>
								<p className="elementor-icon-box-description">Crowdfunding</p>
							</div>
		</div>
				</div>
				</div>
				<div className="elementor-element elementor-element-7a7d6b49 elementor-widget elementor-widget-heading" data-id="7a7d6b49" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<p className="elementor-heading-title elementor-size-default"><span  style={{fontWeight: 'bold'}}>Start with a small investment</span><br />Coming Soon
</p>		
{/* <a href="#" className="elementor-button-link elementor-button elementor-size-sm" style={{background: 'white', color: 'black', marginTop: 10}} role="button">
						<span className="elementor-button-content-wrapper">
						<span className="elementor-button-text" >Invest as little as R2000</span>
		</span></a> */}
        </div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
				{/* <section className="elementor-element elementor-element-42678580 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="42678580" data-element_type="section">
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-541b06d elementor-column elementor-col-33 elementor-inner-column" data-id="541b06d" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-2fdcec9a elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="2fdcec9a" data-element_type="widget" data-widget_type="icon-box.default">
				<div className="elementor-widget-container">
					<div className="elementor-icon-box-wrapper">
						<div className="elementor-icon-box-icon">
				<span className="elementor-icon elementor-animation-" >
				<i className="fa fa-legal" aria-hidden="true"></i>				</span>
			</div>
						<div className="elementor-icon-box-content">
				<h3 className="elementor-icon-box-title">
					<span ></span>
				</h3>
								<p className="elementor-icon-box-description">Criminal Law</p>
							</div>
		</div>
				</div>
				</div>
				<div className="elementor-element elementor-element-544db539 elementor-widget elementor-widget-heading" data-id="544db539" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<p className="elementor-heading-title elementor-size-default">Excepteur sint occaecat cupidatat non<br/> proident sunt in culpa qui officia deserunt<br/> mollit anim id est laborum. 
</p>		</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-d3a6b66 elementor-column elementor-col-33 elementor-inner-column" data-id="d3a6b66" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-2f2b9800 elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="2f2b9800" data-element_type="widget" data-widget_type="icon-box.default">
				<div className="elementor-widget-container">
					<div className="elementor-icon-box-wrapper">
						<div className="elementor-icon-box-icon">
				<span className="elementor-icon elementor-animation-" >
				<i className="fa fa-binoculars" aria-hidden="true"></i>				</span>
			</div>
						<div className="elementor-icon-box-content">
				<h3 className="elementor-icon-box-title">
					<span ></span>
				</h3>
								<p className="elementor-icon-box-description">Drug Offence</p>
							</div>
		</div>
				</div>
				</div>
				<div className="elementor-element elementor-element-ec06c28 elementor-widget elementor-widget-heading" data-id="ec06c28" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<p className="elementor-heading-title elementor-size-default">Excepteur sint occaecat cupidatat non<br/> proident sunt in culpa qui officia deserunt<br/> mollit anim id est laborum. 
</p>		</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-8ee965c elementor-column elementor-col-33 elementor-inner-column" data-id="8ee965c" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-410ad440 elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="410ad440" data-element_type="widget" data-widget_type="icon-box.default">
				<div className="elementor-widget-container">
					<div className="elementor-icon-box-wrapper">
						<div className="elementor-icon-box-icon">
				<span className="elementor-icon elementor-animation-" >
				<i className="fa fa-folder-open" aria-hidden="true"></i>				</span>
			</div>
						<div className="elementor-icon-box-content">
				<h3 className="elementor-icon-box-title">
					<span ></span>
				</h3>
								<p className="elementor-icon-box-description">Financial Law</p>
							</div>
		</div>
				</div>
				</div>
				<div className="elementor-element elementor-element-5caba0a6 elementor-widget elementor-widget-heading" data-id="5caba0a6" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<p className="elementor-heading-title elementor-size-default">Excepteur sint occaecat cupidatat non<br/> proident sunt in culpa qui officia deserunt<br/> mollit anim id est laborum. 
</p>		</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section> */}
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
				<section className="elementor-element elementor-element-195e6780 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="195e6780" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classNameic&quot;}">
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-36ca067e elementor-column elementor-col-50 elementor-top-column" data-id="36ca067e" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-198a371b elementor-widget elementor-widget-image" data-id="198a371b" data-element_type="widget" data-widget_type="image.default">
				<div className="elementor-widget-container">
					<div className="elementor-image">
										<img width="540" height="391" src="/imgs/view-projects.jpg" className="attachment-full size-full" alt="" srcSet="/imgs/view-projects.jpg 540w, /imgs/view-projects.jpg 300w" sizes="(max-width: 540px) 100vw, 540px" />											</div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-5bef229 elementor-column elementor-col-50 elementor-top-column" data-id="5bef229" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-324ac34a elementor-widget elementor-widget-heading" data-id="324ac34a" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h3 className="elementor-heading-title elementor-size-default">View Our Developments</h3>		</div>
				</div>
				<div className="elementor-element elementor-element-2bf50cd6 elementor-widget elementor-widget-heading" data-id="2bf50cd6" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<p className="elementor-heading-title elementor-size-default">TJC has a broad skill set in property development to select great developments, plus the network to connect these projects to investors and it has the experience to help ensure the property is governed well. </p>		</div>
				</div>
				<div className="elementor-element elementor-element-31ea795a elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="31ea795a" data-element_type="widget" data-widget_type="icon-box.default">
				<div className="elementor-widget-container">
					{/* <div className="elementor-icon-box-wrapper">
						<div className="elementor-icon-box-icon">
				<span className="elementor-icon elementor-animation-" >
				<i className="fa fa-check" aria-hidden="true"></i>				</span>
			</div>
						<div className="elementor-icon-box-content">
				<span className="elementor-icon-box-title">
					<span >You get Item 1</span>
				</span>
							</div>
		</div>
				</div>
				</div>
				<div className="elementor-element elementor-element-28d03916 elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="28d03916" data-element_type="widget" data-widget_type="icon-box.default">
				<div className="elementor-widget-container">
					<div className="elementor-icon-box-wrapper">
						<div className="elementor-icon-box-icon">
				<span className="elementor-icon elementor-animation-" >
				<i className="fa fa-check" aria-hidden="true"></i>				</span>
			</div>
						<div className="elementor-icon-box-content">
				<span className="elementor-icon-box-title">
					<span >You get Item 2</span>
				</span>
							</div>
		</div>
				</div>
				</div>
				<div className="elementor-element elementor-element-215cd2df elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="215cd2df" data-element_type="widget" data-widget_type="icon-box.default">
				<div className="elementor-widget-container">
					<div className="elementor-icon-box-wrapper">
						<div className="elementor-icon-box-icon">
				<span className="elementor-icon elementor-animation-" >
				<i className="fa fa-check" aria-hidden="true"></i>				</span>
			</div>
						<div className="elementor-icon-box-content">
				<span className="elementor-icon-box-title">
					<span >You get Item 3</span>
				</span>
							</div>
		</div> */}
				</div>
				</div>
				{/* <div className="elementor-element elementor-element-a52257 elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="a52257" data-element_type="widget" data-widget_type="icon-box.default">
				<div className="elementor-widget-container">
					<div className="elementor-icon-box-wrapper">
						<div className="elementor-icon-box-icon">
				<span className="elementor-icon elementor-animation-" >
				<i className="fa fa-check" aria-hidden="true"></i>				</span>
			</div>
						<div className="elementor-icon-box-content">
				<span className="elementor-icon-box-title">
					<span >You get Item 4</span>
				</span>
							</div>
		</div>
				</div>
				</div> */}
				<div className="elementor-element elementor-element-3cb672eb elementor-widget elementor-widget-button" data-id="3cb672eb" data-element_type="widget" data-widget_type="button.default">
				<div className="elementor-widget-container">
					<div className="elementor-button-wrapper">
			<Link to='/projects' className="elementor-button-link elementor-button elementor-size-sm" role="button">
						<span className="elementor-button-content-wrapper">
						<span className="elementor-button-text">View Projects</span>
		</span>
					</Link>
		</div>
				</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
				<section className="elementor-element elementor-element-12731d6d elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="12731d6d" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classNameic&quot;}">
						<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-1044c9e7 elementor-column elementor-col-100 elementor-top-column" data-id="1044c9e7" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-1acd5c52 elementor-widget elementor-widget-heading" data-id="1acd5c52" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h3 className="elementor-heading-title elementor-size-default">"We created the Joseph Company after being in the built environment industry for over a decade."</h3>		</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
				<section id="testimonials" className="elementor-element elementor-element-cf088b6 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="cf088b6" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classNameic&quot;}">
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-25de22ef elementor-column elementor-col-100 elementor-top-column" data-id="25de22ef" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-77f01267 elementor-widget elementor-widget-heading" data-id="77f01267" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h3 className="elementor-heading-title elementor-size-default">Our Testimonials</h3>		</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
				<section className="elementor-element elementor-element-462af54f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="462af54f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
						<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-766ba41c hover-slide-up elementor-column elementor-col-50 elementor-top-column" data-id="766ba41c" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-631cd3ee elementor-widget elementor-widget-testimonial" data-id="631cd3ee" data-element_type="widget" data-widget_type="testimonial.default">
				<div className="elementor-widget-container">
					<div className="elementor-testimonial-wrapper elementor-testimonial-text-align-left">
							<div className="elementor-testimonial-content">"When I joined The Joseph Company, I didn't have enough capital. They organized equity raising events. They invited people from their network. The results were amazing, people were really interested in my proposal."<br />-<br/><a target='_blank' rel="noreferrer" href="https://youtu.be/QpeWgBbVeQY">Watch Video</a></div>
			
						<div className="elementor-testimonial-meta elementor-has-image elementor-testimonial-image-position-aside">
				<div className="elementor-testimonial-meta-inner">
											<div className="elementor-testimonial-image">
							<img src="imgs/freddy.png" title="ava-1.png" alt="ava-1.png" />						</div>
					
										<div className="elementor-testimonial-details">
														<div className="elementor-testimonial-name">Freddy Ramela</div>
																						<div className="elementor-testimonial-job">Lapeng Heights</div>
													</div>
									</div>
			</div>
					</div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-376a8927 hover-slide-up elementor-column elementor-col-50 elementor-top-column" data-id="376a8927" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-3ccd7551 elementor-widget elementor-widget-testimonial" data-id="3ccd7551" data-element_type="widget" data-widget_type="testimonial.default">
				<div className="elementor-widget-container">
					<div className="elementor-testimonial-wrapper elementor-testimonial-text-align-left">
							<div className="elementor-testimonial-content">"We will be placing 300 students next to TUT. We bought the property through an auction as an incomplete structure. We will be building it up from 2020 February and it will be completed in 2021 February."<br />-<br /><a target='_blank' rel="noreferrer" href="https://youtu.be/imIZ0Djpvo4">Watch Video</a></div>
			
						<div className="elementor-testimonial-meta elementor-has-image elementor-testimonial-image-position-aside">
				<div className="elementor-testimonial-meta-inner">
											<div className="elementor-testimonial-image">
							<img src="imgs/thabo.png" title="ava-1.png" alt="ava-1.png" />						</div>
					
										<div className="elementor-testimonial-details">
														<div className="elementor-testimonial-name">Thabo Mongoato</div>
																						<div className="elementor-testimonial-job">Hao Rona</div>
													</div>
									</div>
			</div>
					</div>
				</div>
				</div>
						</div>
			</div>
		</div>
				{/* <div className="elementor-element elementor-element-6bf90d51 hover-slide-up elementor-column elementor-col-33 elementor-top-column" data-id="6bf90d51" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-7bad548a elementor-widget elementor-widget-testimonial" data-id="7bad548a" data-element_type="widget" data-widget_type="testimonial.default">
				<div className="elementor-widget-container">
					<div className="elementor-testimonial-wrapper elementor-testimonial-text-align-left">
							<div className="elementor-testimonial-content">“atheme has allowed our department to gain control of software distribution very nicely… it is very easy to use and understand.”​</div>
			
						<div className="elementor-testimonial-meta elementor-has-image elementor-testimonial-image-position-aside">
				<div className="elementor-testimonial-meta-inner">
											<div className="elementor-testimonial-image">
							<img src="uploads/sites/96/elementor/thumbs/ava-1-o8mmsh48qfpi45kxrhow4k1kabtz96qjhyfs8d1a8w.png" title="ava-1.png" alt="ava-1.png" />						</div>
					
										<div className="elementor-testimonial-details">
														<div className="elementor-testimonial-name">John Doe</div>
																						<div className="elementor-testimonial-job">Designer</div>
													</div>
									</div>
			</div>
					</div>
				</div>
				</div>
						</div>
			</div>
		</div> */}
						</div>
			</div>
		</section>
				<section className="elementor-element elementor-element-22d695f6 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="22d695f6" data-element_type="section">
						<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-2d03e191 elementor-column elementor-col-100 elementor-top-column" data-id="2d03e191" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-8e7bff0 elementor-widget elementor-widget-heading" data-id="8e7bff0" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h3 className="elementor-heading-title elementor-size-default">Our Founders</h3>		</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
				<section className="elementor-element elementor-element-6cbe7d83 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="6cbe7d83" data-element_type="section">
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-2f42b334 elementor-column elementor-col-50 elementor-top-column" data-id="2f42b334" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<section className="elementor-element elementor-element-34eaab02 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="34eaab02" data-element_type="section">
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-6f6b6711 elementor-column elementor-col-50 elementor-inner-column" data-id="6f6b6711" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-11d6564d elementor-widget elementor-widget-image" data-id="11d6564d" data-element_type="widget" data-widget_type="image.default">
				<div className="elementor-widget-container">
					<div className="elementor-image">
										<img width="280" height="350" src="imgs/ceo.jpg" className="attachment-full size-full" alt="" srcSet="/imgs/ceo.jpg 250w, /imgs/ceo.jpg 242w" sizes="(max-width: 250px) 100vw, 250px" />											</div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-747a62c9 elementor-column elementor-col-50 elementor-inner-column" data-id="747a62c9" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-468e335c elementor-widget elementor-widget-heading" data-id="468e335c" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h3 className="elementor-heading-title elementor-size-default"><a href="http://demo.themecitizen.com/airi/jayden-gill/">Dandy Matamela</a></h3>		</div>
				</div>
				<div className="elementor-element elementor-element-422f9f7f elementor-widget elementor-widget-heading" data-id="422f9f7f" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h3 className="elementor-heading-title elementor-size-default">CEO</h3>		</div>
				</div>
				<div className="elementor-element elementor-element-8f59e2c elementor-widget elementor-widget-heading" data-id="8f59e2c" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<p className="elementor-heading-title elementor-size-default">Dandy Matamela is an entrepreneur and founder of several vertically integrated
enterprises within the built environment.</p>		</div>
				</div>
				<div className="elementor-element elementor-element-7d5839da elementor-shape-circle elementor-widget elementor-widget-social-icons" data-id="7d5839da" data-element_type="widget" data-widget_type="social-icons.default">
				<div className="elementor-widget-container">
					<div className="elementor-social-icons-wrapper">
							<a className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-repeater-item-cda5a99" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/dandy-matamela-68580448">
					<span className="elementor-screen-only">Linkedin</span>
											<i className="fa fa-linkedin"></i>
									</a>
							{/* <a className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-962410e" target="_blank">
					<span className="elementor-screen-only">Twitter</span>
											<i className="fa fa-twitter"></i>
									</a>
							<a className="elementor-icon elementor-social-icon elementor-social-icon-google-plus elementor-repeater-item-62fc347" target="_blank">
					<span className="elementor-screen-only">Google-plus</span>
											<i className="fa fa-google-plus"></i>
									</a> */}
					</div>
				</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-5bb95a79 elementor-column elementor-col-50 elementor-top-column" data-id="5bb95a79" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<section className="elementor-element elementor-element-6c4b39f2 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="6c4b39f2" data-element_type="section">
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-56477de2 elementor-column elementor-col-50 elementor-inner-column" data-id="56477de2" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-1d4a9bdf elementor-widget elementor-widget-image" data-id="1d4a9bdf" data-element_type="widget" data-widget_type="image.default">
				<div className="elementor-widget-container">
					<div className="elementor-image">
										<img width="280" height="350" src="imgs/operations.jpg" className="attachment-full size-full" alt="" srcSet="/imgs/operations.jpg 280w, /imgs/operations.jpg 272w" sizes="(max-width: 280px) 100vw, 280px" />											</div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-74ce6b1c elementor-column elementor-col-50 elementor-inner-column" data-id="74ce6b1c" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-2fe7d58c elementor-widget elementor-widget-heading" data-id="2fe7d58c" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h3 className="elementor-heading-title elementor-size-default"><a href="http://demo.themecitizen.com/airi/jayden-gill/">Lufuno Moses Ramabulana</a></h3>		</div>
				</div>
				<div className="elementor-element elementor-element-5a010eca elementor-widget elementor-widget-heading" data-id="5a010eca" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<h3 className="elementor-heading-title elementor-size-default">COO</h3>		</div>
				</div>
				<div className="elementor-element elementor-element-5c06700b elementor-widget elementor-widget-heading" data-id="5c06700b" data-element_type="widget" data-widget_type="heading.default">
				<div className="elementor-widget-container">
			<p className="elementor-heading-title elementor-size-default">{mosesProfile} </p>		</div>
				</div>
				<div className="elementor-element elementor-element-49463d0c elementor-shape-circle elementor-widget elementor-widget-social-icons" data-id="49463d0c" data-element_type="widget" data-widget_type="social-icons.default">
				<div className="elementor-widget-container">
					<div className="elementor-social-icons-wrapper">
							<a href="https://www.linkedin.com/in/lufuno-moses-ramabulana-2196644b" className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-repeater-item-cda5a99" target="_blank" rel="noreferrer">
					<span className="elementor-screen-only">Linkedin</span>
											<i className="fa fa-linkedin"></i>
									</a>
							{/* <a className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-962410e" target="_blank">
					<span className="elementor-screen-only">Twitter</span>
											<i className="fa fa-twitter"></i>
									</a>
							<a className="elementor-icon elementor-social-icon elementor-social-icon-google-plus elementor-repeater-item-62fc347" target="_blank">
					<span className="elementor-screen-only">Google-plus</span>
											<i className="fa fa-google-plus"></i>
									</a> */}
					</div>
				</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
		
				<section className="elementor-element elementor-element-4d70cfe8 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="4d70cfe8" data-element_type="section">
				<div className="elementor-widget-container">
					<h3 className="elementor-heading-title elementor-size-default" style={{textAlign: 'center', marginTop: 80, fontSize: 44, fontWeight: 400, marginBottom: 40}}>Investments Made</h3>
				</div>
				
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-68a9e32f elementor-column elementor-col-33 elementor-top-column" data-id="68a9e32f" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-12295ce1 counter-text-left elementor-widget elementor-widget-counter" data-id="12295ce1" data-element_type="widget" data-widget_type="counter.default">
				<div className="elementor-widget-container">
					<div className="elementor-counter">
			<div className="elementor-counter-number-wrapper">
				<span className="elementor-counter-number-prefix"></span>
				<span className="elementor-counter-number" data-duration="2000" data-to-value="1200" data-from-value="0">R243</span>
				<span className="elementor-counter-number-suffix">M</span>
			</div>
							<div className="elementor-counter-title">Business Capital Value</div>
					</div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-89500fe elementor-column elementor-col-33 elementor-top-column" data-id="89500fe" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-4fdb5171 counter-text-left elementor-widget elementor-widget-counter" data-id="4fdb5171" data-element_type="widget" data-widget_type="counter.default">
				<div className="elementor-widget-container">
					<div className="elementor-counter">
			<div className="elementor-counter-number-wrapper">
				<span className="elementor-counter-number-prefix"></span>
				<span className="elementor-counter-number" data-duration="2000" data-to-value="120" data-from-value="0" data-delimiter=",">R681</span>
				<span className="elementor-counter-number-suffix">M</span>
			</div>
							<div className="elementor-counter-title">Business Value</div>
					</div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-745da99 elementor-column elementor-col-33 elementor-top-column" data-id="745da99" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-25775880 counter-text-left elementor-widget elementor-widget-counter" data-id="25775880" data-element_type="widget" data-widget_type="counter.default">
				<div className="elementor-widget-container">
					<div className="elementor-counter">
			<div className="elementor-counter-number-wrapper">
				<span className="elementor-counter-number-prefix"></span>
				<span className="elementor-counter-number" data-duration="2000" data-to-value="5800" data-from-value="0">R28</span>
				<span className="elementor-counter-number-suffix">M</span>
			</div>
							<div className="elementor-counter-title">Private Equity Invested</div>
					</div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-117065d1 elementor-column elementor-col-25 elementor-top-column" data-id="117065d1" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated">
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-4425588 counter-text-left elementor-widget elementor-widget-counter" data-id="4425588" data-element_type="widget" data-widget_type="counter.default">
				<div className="elementor-widget-container">
					<div className="elementor-counter">
			<div className="elementor-counter-number-wrapper">
				<span className="elementor-counter-number-prefix"></span>
				<span className="elementor-counter-number" data-duration="2000" data-to-value="8400" data-from-value="0">R18</span>
				<span className="elementor-counter-number-suffix">M</span>
			</div>
							<div className="elementor-counter-title">Investment raised... S12J</div>
					</div>
				</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
				<section className="elementor-element elementor-element-61300b44 elementor-section-stretched elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="61300b44" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classNameic&quot;}">
						<div className="elementor-container elementor-column-gap-no">
				<div className="elementor-row">
				<div className="elementor-element elementor-element-3e744762 elementor-column elementor-col-50 elementor-top-column" data-id="3e744762" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
			<div className="elementor-column-wrap  elementor-element-populated" style={{backgroundSize: 'cover', backgroundPosition: 'center center'}}>
					<div className="elementor-widget-wrap">
				<div className="elementor-element elementor-element-d2a1578 elementor-widget elementor-widget-spacer" data-id="d2a1578" data-element_type="widget" data-widget_type="spacer.default">
				<div className="elementor-widget-container">
					<div className="elementor-spacer">
			<div className="elementor-spacer-inner"></div>
		</div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div className="elementor-element elementor-element-36d5993a elementor-column elementor-col-50 elementor-top-column" data-id="36d5993a" data-element_type="column">
			<div className="elementor-column-wrap  elementor-element-populated" style={{paddingRight: 40}}>
					<FormContact title='Free Consultation' />
			</div>
		</div>
						</div>
			</div>
		</section>
						</div>
			</div>
		</div>
					
		</main>
	</div>


	</div>

	
	
	
	
	<div id="sidebar-footer" className="footer-widgets" role="complementary">
		<div className="container">
			<Row style={{fontSize: '1.2em'}} gutter={[32, 32]}>
							<Col md={8}>
					<section id="media_image-1" className="widget widget_media_image">
						{/* <a href="#"><img width="180px" src="imgs/logo.png" className="image wp-image-148  attachment-full size-full" alt="" style={{maxWidth: '100%', height: 'auto'}} /></a> */}
						</section><section id="custom_html-1" className="widget_text widget widget_custom_html"><div className="textwidget custom-html-widget"><p className="about-us-footer">
					TJC has a broad skill set in property development to select great developments, it has the network to connect these projects to investors and it has the experience to help ensure the property is governed well.  The company
</p></div></section><section id="athemes_social_widget-1" className="widget widget_athemes_social_widget">                <div className="menu-social-container"><ul id="menu-social" className="menu social-media-list clearfix"><li id="menu-item-150" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-150"><a target='_blank' rel="noreferrer" href="https://www.facebook.com/theJosephCompanyEquity"><span className="screen-reader-text">Facebook</span></a></li>
{/* <li id="menu-item-151" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-151"><a href="http://instagram.com/"><span className="screen-reader-text">Instagram</span></a></li> */}
<li id="menu-item-152" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-152"><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/posts/the-joseph-company-equity-investments_investsa-venturecapitalist-gauteng-activity-6690893921301626880-zB6F"><span className="screen-reader-text">Linkedin</span></a></li>
</ul></div>        </section>				</Col>
			
										<Col md={8}>
					<section id="custom_html-2" className="widget_text widget widget_custom_html"><h3 className="widget-title" style={{color: 'white'}}>Contact Us</h3><div className="textwidget custom-html-widget"><p style={{marginBottom: 12}}>
					Plot 293, Protea Road Cnr Methley Road, Pomona AH, 1619
</p>
<p style={{marginBottom: 6}} >
	<span style={{color: '#F1A52E', marginRight: 5}}>Phone:</span>(+27) 012 546 0966
</p>
<p style={{marginBottom: 6}}>
	<span style={{color: '#F1A52E', marginRight: 5}}>Email:</span><a href="mailto:info@thejosephcompany.co.za" className="__cf_email__" >info@thejosephcompany.co.za</a>

</p>
<p>
	<span style={{color: '#F1A52E', marginRight: 5}}>Website:</span>www.thejosephcompany.co.za
</p></div></section>				</Col>

<Col md={8}>
					<section id="custom_html-2" className="widget_text widget widget_custom_html"><h3 className="widget-title" style={{color: 'white'}}>Partners</h3><div className="textwidget custom-html-widget">
					<img src='/imgs/partner-4.png' style={{height: 60, marginBottom: 20}} />
					<img src='/imgs/partner-5.png' style={{height: 60, marginBottom: 20}} />
					{/* <img src='/imgs/partner-2.png' style={{height: 60, marginBottom: 20}} />
					<img src='/imgs/partner-3.jpg' style={{height: 60, marginBottom: 20}} /> */}
					</div></section>				</Col>
					
			</Row>
		</div>	
	</div>
	<footer id="colophon" className="site-footer">
		<div className="container">
			<div className="row">
					
	<div className="site-info col-md-12">
		
					{/* <a href="https://wordpress.org/">Proudly powered by WordPress</a> */}
			{/* <span className="sep"> | </span> */}
			&copy; The Joseph Company 2020		</div>
	
				</div>
		</div>
	</footer>
</div>
    )
}

export default HomePage